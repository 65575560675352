.ui.card.presentationCardStyle {
    background-color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    color: #1d2c35;
    font-weight: 700;
    margin: 30px 0;
    max-width: 1300px;
    padding: 20px 40px;
    width: 93%;
}

.ui.card.subCardStyle {
    background-color: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 0;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    color: #1d2c35;
    font-weight: 700;
    margin: 10px 0 38px;
    padding: 25px 35px 30px;
    width: 95%;
}

.blueBox {
    background-color: #021e2f;
    height: 2.9em;
    margin: 12px 10px 0 10px;
    width: 2.9em;
}

.buttonRow {
    margin: 5px 5px 5px auto;
    width: 150px;
}