.ui.card.smallCardStyle {
    background-color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    color:#1d2c35;
    font-weight: 700;
    margin: 15px;
    padding: 10px 15px;
    width: 94%;
}