.ui.button.iconButton, .ui.button.textButton {
    background-color:#fff;
    border: 2px solid #dadada;
    border-radius: 0;
    color:#717171;
}

.ui.button.iconButton:hover, .ui.button.textButton:hover {
    background-color: #ff8800;
    color:#fff;
    transition: 0.3s;
}

.ui.button.iconButton {
    height: 47px;
    margin: 4px 5px;
    width: 38px;
}

.ui.button.textButton {
    display: inline-block;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1em;
    margin: 4px 15px;
    max-height: 47px;
    min-width: 210px;
    padding: 14px 23px;
    text-align: center;
    text-transform: uppercase;
}