.headline, .headlineCard,.headlineSmall, .greySubHeadline, .greySubHeadlineSmall, .blueSubHeadline, .lighterBlueSubHeadline,
.greyTextCard, .greyText, .lighterGreyText, .lighterGreyTextSmall  {
    font-family: 'Open Sans', Arial, sans-serif;
}

.appHeadline {
    color: #021e2f;
    font-family: Verdana, sans-serif;
    font-size: 1.875em;
    font-weight: 700;
    line-height: 1em;
}

.headline, .headlineCard, .headlineSmall {
    color: #021e2f;
    display: inline;
    font-weight: 800;
    line-height: 1.1em;
    margin: 6px 6px 1rem;
    text-transform: uppercase;
}

.headline { /*In front page*/
    font-size: 4.375em;
}

.headlineCard { /*In presentation card*/
    font-size: 4em;
}

.headlineSmall { /*In small card*/
    font-size: 2em;
}

.greySubHeadline { /*In presentation card and SDG card*/
    color: #9B9B9B;
    font-size: 1.75em;
    font-weight: 600;
    line-height: 1.1em;
    margin: 6px;
}

.greySubHeadlineSmall { /*In small card*/
    color: #9B9B9B;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.1em;
    margin: 2px 0
}

.blueSubHeadline { /* In milstone card etc.*/
    color: #021e2f;
    font-size: 1.4375em;
    font-weight: 700;
    line-height: 1.4;
    margin: 0.2rem 0 0.3em;
    text-transform: uppercase;
}

.lighterBlueSubHeadline {
    color: #021e2f;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
}

.orangeUppercaseText {
    color: #ff8800;
    display: inline;
    font-weight: 700;
    text-transform: uppercase;
}

.blueUppercaseText {
    color: #021e2f;
    display: inline;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.greyTextCard {
    color: #3d3d3d;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5625em;
    margin: 1px 0 3px
}

.greyText {
    color: #3d3d3d;
    font-size: 0.9em;
    font-weight: 400;
    margin: 6px 0;
}

.lighterGreyText {
    color: #717171;
    font-size: 0.9em;
    font-weight: 400;
}

.lighterGreyTextSmall {
    color: #717171;
    font-size: 0.8em;
    font-weight: 400;
}

ul {
    margin: 7px 5px;
}

.quote { /*On front page*/
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-size: 1.125em;
    color: #717171;
    line-height: 1.5em;
    font-weight: 400;
    margin: 1.25rem 0;
    text-rendering: optimizeLegibility;
    padding: 0;
    box-sizing: border-box;
    text-align: center !important;
}

.dividerLine { /*On front page*/
    content: ' ';
    display: block;
    border: 1px solid #979797;
    width: 110px;
    margin: 30px auto 35px auto;
}